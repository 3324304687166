@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

body {
  font-family: "Nunito Sans", sans-serif;
  background: #3dc8af2e;
  width: 100%;
  height: 100%;
  background-size: cover;
  margin: 0;
  height: 100vh;
  color: #3f4750;
  font-size: 0.9rem;
}

/* Login Page */

.card.shadow-login .form-control:focus {
  box-shadow: none;
  border: 1px solid #30bb92 !important;
}

.shadow-login {
  background-image: linear-gradient(-60deg, #e3f3ff 0%, #3dc8af6b 100%);
}

.card.shadow-login {
  position: relative;
  z-index: 999999;
}

.min-vh {
  height: 100vh;
}

.text-black {
  color: #000;
}

.text-blue {
  color: #3dc8af;
  font-weight: 900 !important;
}

.form-label {
  color: black;
  font-weight: 500;
}

.fc-size .form-control {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border-color: white;
}

.fc-size .form-control:focus {
  box-shadow: none;
  border-color: rgba(61, 185, 162, 1);
}

.btn-linear {
  background: #3dc8af;
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 23px 24px -10px rgb(61 200 175 / 45%);
  border-radius: 11px;
}

.btn-linear:hover {
  background: white;
  border: 1px solid #3dc8af;
  color: #3dc8af;
}

::placeholder {
  color: #2a3342;
  font-size: 14px;
  font-weight: 400;
}

.card.shadow-login::before {
  content: "";
  position: absolute;
  left: -50px;
  bottom: -10%;
  width: 100px;
  height: 100px;
  background: linear-gradient(271.86deg, #3dc8af 0.13%, #5debd1 97.84%);
  z-index: 0;
  border-radius: 50%;
  box-shadow: 0px 23px 24px -10px rgb(61 200 175 / 45%);
}

.card.shadow-login::after {
  content: "";
  position: absolute;
  right: -50px;
  top: -50px;
  width: 150px;
  height: 150px;
  background: linear-gradient(271.86deg, #3dc8af 0.13%, #5debd1 97.84%);
  z-index: 0;
  border-radius: 50%;
  box-shadow: 0px 23px 24px -10px rgb(61 200 175 / 45%);
}

/* OTP */

.inputfield {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.input {
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 11px;
  height: 2.8em;
  width: 2.8em;
  outline: none;
  text-align: center;
  font-size: 1.5em;
  background-color: #ffffff;
  outline: none;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-svg {
  background: url("../images/svg-bg.svg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-position: center center;
}

/* MAIN LAYOUT CSS */

.layout {
  min-height: 100vh;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout.has-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.layout .header {
  -webkit-transition: width, 0.3s;
  transition: width, 0.3s;
  height: 64px;
  min-height: 64px;
  position: relative;
}

.layout .sidebar {
  width: 280px;
  min-width: 280px;
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
}

.layout .sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

.layout .footer {
  height: 64px;
  min-height: 64px;
}

.layout .content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  display: none;
}

.layout .sidebar-toggler {
  display: none;
}

.layout.fixed-sidebar {
  height: 100%;
}

.layout.fixed-sidebar .sidebar {
  height: 100%;
  overflow: auto;
}

.layout.fixed-sidebar .sidebar ~ .layout {
  height: 100%;
  overflow: auto;
}

.layout.fixed-header .header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.layout.fixed-header .header ~ .layout,
.layout.fixed-header .header ~ .content {
  margin-top: 64px;
}

.layout.fixed-header.fixed-sidebar .header {
  width: calc(100% - 280px);
}

.layout.fixed-header.fixed-sidebar .sidebar.collapsed ~ .layout .header {
  width: calc(100% - 80px);
}

.layout.rtl {
  direction: rtl;
}

.layout .navbar {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.nav-item + .nav-item::before {
  float: left;
  color: #6c757d;
  /* content: "/"; */
  margin-top: 7px;
}

.layout .nav-item .nav-link svg {
  vertical-align: bottom;
}

.layout .navbar-toggler {
  box-shadow: none;
}

.layout .nav-item .nav-link {
  font-weight: bold;
  color: #000;
  margin: auto 10px !important;
}

.layout .nav-item .nav-link:hover {
  color: #0092d0;
}

.layout .dropdown-toggle span {
  color: black;
  font-weight: bold;
}

.layout .dropdown-menu[data-bs-popper] {
  left: -35px;
  top: 120%;
}

.aos {
  font-weight: bold;
  color: #00519c;
}

.layout .menu-item a:hover {
  background: #3dc8af;
  font-weight: 600;
}

.layout {
  z-index: 1;
}

.layout .header {
  display: flex;
  align-items: center;
  padding: 20px;
}

.layout .content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #3dc8af2e;
}

.layout .footer {
  text-align: center;
  margin-top: auto;
  margin-bottom: 20px;
  padding: 20px;
}

.sidebar {
  color: #7d84ab;
  overflow-x: hidden !important;
  position: relative;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: #3dc8af;
}

.sidebar::-webkit-scrollbar {
  width: 3px;
  background-color: #3dc8af;
}

.sidebar .image-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}

.sidebar .image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.sidebar.has-bg-image .image-wrapper {
  display: block;
}

.sidebar .sidebar-layout {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  z-index: 2;
  box-shadow: 15px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.sidebar .sidebar-layout .sidebar-header {
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.sidebar .sidebar-layout .sidebar-header > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar .sidebar-layout .sidebar-content {
  flex-grow: 1;
  padding: 10px 0;
}

.sidebar .sidebar-layout .sidebar-footer {
  height: 230px;
  min-height: 230px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.sidebar .sidebar-layout .sidebar-footer > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

.layout .sidebar .menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.layout .sidebar .menu .menu-header {
  font-weight: 600;
  padding: 10px 25px;
  font-size: 0.8em;
  letter-spacing: 2px;
  transition: opacity 0.3s;
  opacity: 0.5;
}

.layout .sidebar .menu .menu-item a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 12px;
  color: #000;
  margin: 0px 12px;
  border-radius: 10px;
}

.layout .sidebar .menu .menu-item a .menu-icon {
  font-size: 1.2rem;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  border-radius: 2px;
  transition: color 0.3s;
  color: #000;
}

.layout .sidebar .menu .menu-item a .menu-icon i {
  display: inline-block;
}

.layout .sidebar .menu .menu-item a .menu-title {
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  transition: color 0.3s;
  font-weight: 600;
}

.layout .sidebar .menu .menu-item a .menu-prefix,
.layout .sidebar .menu .menu-item a .menu-suffix {
  display: inline-block;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.3s;
}

.layout .sidebar .menu .menu-item a:hover .menu-title {
  color: #fff;
}

.layout .sidebar .menu .menu-item a:hover .menu-icon {
  color: #fff;
}

.layout .sidebar .menu .menu-item a:hover .menu-icon i {
  animation: swing ease-in-out 0.5s 1 alternate;
}

.layout .sidebar .menu .menu-item a:hover::after {
  border-color: #fff !important;
}

.layout .sidebar .menu .menu-item.sub-menu {
  position: relative;
}

.layout .sidebar .menu .menu-item.sub-menu > a::after {
  content: "";
  transition: transform 0.3s;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  width: 5px;
  height: 5px;
  transform: rotate(-45deg);
}

.layout .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
  display: none;
  overflow: hidden;
  z-index: 999;
}

.layout .sidebar .menu .menu-item.sub-menu.open ul .menu-item a {
  padding-left: 64px;
}

.layout .sidebar .menu .menu-item.sub-menu.open > a {
  color: #3dc8af;
}

.layout .sidebar .menu .menu-item.sub-menu.open > a::after {
  transform: rotate(45deg);
}

.layout .sidebar .menu .menu-item.active > a {
  color: #fff;
  background: #3dc8af;
}

.layout .sidebar .menu .menu-item.active > a .menu-title {
  color: #fff;
}

.layout .sidebar .menu .menu-item.active > a::after {
  border-color: #fff;
}

.layout .sidebar .menu .menu-item.active > a .menu-icon {
  color: #fff;
}

.layout .sidebar .menu > ul > .sub-menu > .sub-menu-list {
  background-color: white;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon,
.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon,
.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: #0b1a2c;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon {
  border-radius: 50%;
}

.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon {
  border-radius: 4px;
}

.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  border-radius: 0;
}

.layout
  .sidebar:not(.collapsed)
  .menu
  > ul
  > .menu-item.sub-menu
  > .sub-menu-list {
  visibility: visible !important;
  position: static !important;
  transform: translate(0, 0) !important;
}

.layout .sidebar.collapsed .menu > ul > .menu-header {
  opacity: 0;
}

.layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-prefix,
.layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-suffix {
  opacity: 0;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: currentcolor;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  border: none;
  transform: translateY(-50%);
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a:hover::after {
  background-color: #dee2ec;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > .sub-menu-list {
  transition: none !important;
  width: 200px;
  margin-left: 3px !important;
  border-radius: 4px;
  display: block !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.active > a::after {
  background-color: #dee2ec;
}

.layout .sidebar.has-bg-image .menu.icon-shape-circle .menu-item a .menu-icon,
.layout .sidebar.has-bg-image .menu.icon-shape-rounded .menu-item a .menu-icon,
.layout .sidebar.has-bg-image .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: rgba(11, 26, 44, 0.6);
}

.layout
  .sidebar.has-bg-image:not(.collapsed)
  .menu
  > ul
  > .sub-menu
  > .sub-menu-list {
  background-color: rgb(61 200 175 / 20%);
}

.layout.rtl .sidebar .menu .menu-item a .menu-icon {
  margin-left: 10px;
  margin-right: 0;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu > a::after {
  transform: rotate(135deg);
}

.layout.rtl .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
  padding-left: 0;
  padding-right: 20px;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu.open > a::after {
  transform: rotate(45deg);
}

.layout.rtl .sidebar.collapsed .menu > ul > .menu-item.sub-menu a::after {
  right: auto;
  left: 10px;
}

.layout.rtl
  .sidebar.collapsed
  .menu
  > ul
  > .menu-item.sub-menu
  > .sub-menu-list {
  margin-left: -3px !important;
}

.layout .sidebar .pro-sidebar-logo {
  display: flex;
  align-items: center;
}

.layout .sidebar .pro-sidebar-logo > div {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(271.86deg, #0092d0 0.13%, #00519c 97.84%);
  margin-right: 10px;
}

.layout .sidebar .pro-sidebar-logo > h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 30px;
  transition: opacity 0.3s;
  opacity: 1;
}

.layout .sidebar .footer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8em;
  padding: 20px 0;
  border-radius: 8px;
  width: 180px;
  min-width: 190px;
  margin: 0 auto;
  background-color: #162d4a;
}

.layout .sidebar .footer-box img.react-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.layout .sidebar .footer-box a {
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}

.layout .sidebar .sidebar-collapser {
  transition: left, right, 0.3s;
  position: fixed;
  left: 260px;
  top: 40px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3dc8af;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  transform: translateX(50%);
  z-index: 111;
  cursor: pointer;
  color: white;
  box-shadow: 1px 1px 4px #3dc8af;
}

.layout .sidebar.collapsed .pro-sidebar-logo > h5 {
  opacity: 0;
}

.layout .sidebar.collapsed .footer-box {
  display: none;
}

.layout .sidebar.collapsed .sidebar-collapser {
  left: 60px;
}

.layout .sidebar.collapsed .sidebar-collapser i {
  transform: rotate(180deg);
}

.sidebar-toggler {
  position: fixed;
  right: 20px;
  top: 20px;
}

/* Table */
.table-first tr th {
  color: #6b7280;
  font-size: 14px;
  background: #f9fafb;
  padding-top: 15px;
  padding-bottom: 15px;
  /* border-right: 1px solid #dee2e6; */
}

.borderedd tr th {
  border: 1px solid #dde2e6;
}

.borderedd tr td {
  border-left: 0.5px solid #dde2e6 !important;
  border-right: 0.5px solid #dde2e6 !important;
}

.table-first > tbody > tr:nth-of-type(odd) > * {
  background: rgba(255, 255, 252, 1);
  opacity: 1 !important;
}

.table-first > tbody > tr:nth-of-type(even) > * {
  background: #f9fafb;
}

table.dataTable
  > tbody
  > tr.child
  span.dtr-title
  span.d-inline-flex
  p.mb-0.frst {
  padding-left: 0rem;
}

.table-first tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #111827;
  border: none;
  /* border-right: 1px solid #dee2e6; s*/
}

.breadcrumb-item.active {
  color: black !important;
}

.breadcrumb-item a {
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}

.pagination .active > .page-link {
  background: #3dc8af;
  border-color: #3dc8af !important;
}

.form-selects:focus {
  box-shadow: none;
}

.form-select:focus {
  box-shadow: none;
}

.btn-searchs {
  background: linear-gradient(33deg, #004993 0.13%, #008fa1 97.84%);
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.btn-searchs:hover {
  background: #004993;
  color: white;
  transition: 0.3s;
}

.table-first.text-nowrap {
  white-space: inherit !important;
}

.table-first.text-nowrap thead {
  white-space: nowrap !important;
}

/* Home Page */
.mains-dashboards .card .card-header i {
  font-size: 30px;
  color: white;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 50%;
  background: #df5b5a;
}

.mains-dashboards .card h2 {
  color: black;
  font-weight: 700;
}

.mains-dashboards .card h6 {
  color: #0d0d0d;
  font-weight: 600;
}

.mains-dashboards p a {
  font-weight: bold;
  color: #c2552b;
}

.mains-dashboards p {
  font-size: 12px;
}

.mains-dashboards {
  margin-bottom: 22px;
}

.mains-dashboards .card {
  padding-top: 25px;
  padding-bottom: 25px;
  height: 100%;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  border-radius: 10px;
}

.allCards-new .card {
  border: none;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.mains-dashboards .card .card-header {
  background: none;
}

.mains-dashboards .card-1 .card-header i {
  background: #3dc8af;
}

.dashboard-line {
  color: black;
  border-left: 4px solid #df5b5a;
  padding-left: 8px;
}

.mains-dashboards .card:hover {
  transition: 0.3s ease-in-out;
  transform: translateY(-3px);
  box-shadow: none;
}

.mains-dashboards .card-1 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-1::before {
  content: "";
  background: #3dc8af;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-1::after {
  content: "";
  background: #3dc8af;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-2 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-2::before {
  content: "";
  background: #990099;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-2::after {
  content: "";
  background: #990099;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-3 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-3::before {
  content: "";
  background: #ee9a00;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-3::after {
  content: "";
  background: #ee9a00;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-4 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-4::before {
  content: "";
  background: #00c78c;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-4::after {
  content: "";
  background: #00c78c;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-5 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-5::before {
  content: "";
  background: #05b8cc;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-5::after {
  content: "";
  background: #05b8cc;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-6 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-6::before {
  content: "";
  background: #1d7cf2;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-6::after {
  content: "";
  background: #1d7cf2;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-7 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-7::before {
  content: "";
  background: #00611c;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-7::after {
  content: "";
  background: #00611c;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-8 {
  position: relative;
  overflow: hidden;
}

.mains-dashboards .card-8::before {
  content: "";
  background: #3300ff;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: -28px;
  border-radius: 50%;
  opacity: 0.4;
}

.mains-dashboards .card-8::after {
  content: "";
  background: #3300ff;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  opacity: 0.4;
}

.btn-linear,
.btn-inactive-veh,
.btn-blocks,
.btn-inactive-completed {
  font-weight: 600 !important;
}

table {
  width: 100% !important;
  vertical-align: middle !important;
}

h2 {
  color: black;
}

.bg-none {
  background: none !important;
}

.btn-cancel {
  background: #e7e7e7;
  color: #000;
  font-weight: 500;
}

.btn-deletes {
  background: #3dc8af;
  color: #fff;
  font-weight: 500;
}

.btn-light {
  border: 1px solid #3dc8af;
  color: #3dc8af;
}

.prize {
  color: #3dc8af;
  float: right;
}

/************* MEDIA QUESTIES *************/

@media (max-width: 1600px) {
  .layout .sidebar.break-point-xxl {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-xxl.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-xxl.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-xxl.toggled ~ .overlay {
    display: block;
  }

  .layout .sidebar.break-point-xxl ~ .layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-xxl {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-xxl {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-xxl.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-xxl.toggled {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .mobile-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .logo a img {
    display: block;
    margin: auto;
  }
}

@media (max-width: 1200px) {
  .layout .sidebar.break-point-xl {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-xl.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-xl.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-xl.toggled ~ .overlay {
    display: block;
  }

  .layout .sidebar.break-point-xl ~ .layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-xl {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-xl {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-xl.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-xl.toggled {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .layout .nav-item .nav-link {
    margin: 0px !important;
  }
}

@media (max-width: 992px) {
  .layout .sidebar.break-point-lg {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-lg.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-lg.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-lg.toggled ~ .overlay {
    display: block;
  }

  .layout .sidebar.break-point-lg ~ .layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-lg {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-lg {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-lg.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-lg.toggled {
    left: auto;
    right: 0;
  }

  #btn-collapse {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .border-start {
    border-left: 0px !important;
  }

  .nav-item + .nav-item::before {
    display: none;
  }
}

@media (max-width: 768px) {
  .layout .sidebar.break-point-md {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-md.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-md.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-md.toggled ~ .overlay {
    display: block;
  }

  .layout .sidebar.break-point-md ~ .layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-md {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-md {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-md.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-md.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 767px) {
  .logo a img {
    display: block;
    margin: auto;
  }

  #btn-collapse {
    display: block !important;
  }

  .layout .sidebar.break-point-sm.collapsed {
    left: 0px !important;
  }

  .layout .sidebar .sidebar-collapser {
    left: 60px !important;
    top: 15px !important;
    width: 25px;
    height: 25px;
  }

  .border-start {
    border-left: 0px !important;
  }

  .layout .sidebar.collapsed .sidebar-collapser {
    left: 60px !important;
  }

  .nav-item + .nav-item::before {
    display: none;
  }
}

@media (max-width: 576px) {
  .layout .sidebar.break-point-sm {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-sm.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-sm.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-sm.toggled ~ .overlay {
    display: block;
  }

  .layout .sidebar.break-point-sm ~ .layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-sm {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-sm {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-sm.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-sm.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 480px) {
  .layout .sidebar.break-point-xs {
    position: fixed;
    left: -280px;
    height: 100%;
    top: 0;
    z-index: 100;
  }

  .layout .sidebar.break-point-xs.collapsed {
    left: -80px;
  }

  .layout .sidebar.break-point-xs.toggled {
    left: 0;
  }

  .layout .sidebar.break-point-xs.toggled ~ .overlay {
    display: block;
  }

  .layout .sidebar.break-point-xs ~ .layout .header {
    width: 100% !important;
    -webkit-transition: none;
    transition: none;
  }

  .layout .sidebar-toggler.break-point-xs {
    display: initial;
  }

  .layout.rtl .sidebar.break-point-xs {
    left: auto;
    right: -280px;
  }

  .layout.rtl .sidebar.break-point-xs.collapsed {
    left: auto;
    right: -80px;
  }

  .layout.rtl .sidebar.break-point-xs.toggled {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1201px) and (max-width: 1450px) {
  .card-body.p-md-5 {
    padding: 2rem !important;
  }
  .card.shadow-login::after {
    right: -100px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .card.shadow-login::before {
    bottom: -4%;
  }
  .card.shadow-login::after {
    top: -4%;
    right: -100px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .card.shadow-login::before {
    bottom: -5%;
    left: -90px;
  }
  .card.shadow-login::after {
    top: -5% !important;
    right: -100px;
  }
}

@media (max-width: 767px) {
  .card.shadow-login::before {
    content: "";
    display: none;
  }
  .card.shadow-login::after {
    content: "";
    display: none;
  }
  .input {
    margin-right: 10px;
  }
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 20px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: -29px;
  z-index: 1;
  top: 50%;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-preview img {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #0000001a;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-edit input + label:after {
  content: "";
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  background: url("../images/choose.svg");
  width: 50px;
  background-repeat: no-repeat;
  height: 50px;
}

.custom_Pills .nav-link.active,
.custom_Pills .show > .nav-link {
  border: 1px solid #3dc8af;
  background: #3dc8af;
  color: #fff;
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
}

.custom_Pills .nav-link {
  border: 1px solid grey;
  background: grey;
  color: #fff !important;
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
  margin-right: 10px !important;
}

.custom_Pills .nav-item + .nav-item::before {
  display: none !important;
}

.new-tab .nav-link.active {
  border: 1px solid #3dc8af !important;
  background: #3dc8af !important;
  color: #fff !important;
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
}
.new-tab .nav-link {
  border: 1px solid grey !important;
  background: grey !important;
  color: #fff !important;
  padding: 12px 22px;
  border-radius: 8px;
  font-weight: 700;
  margin-right: 10px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px 0;
  cursor: pointer;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
  display: inline-block;
}

.pagination li a {
  display: block;
  padding: 5px 10px;
  border: 1px solid #ddd;
  text-decoration: none;
  color: #bfede5;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination li a:hover {
  background-color: #c8c8cc;

  border-color: #bfede5;
}

.pagination li.active {
  background-color: #3dc8af;

  border-color: #007bff;
}

.pagination li.previous a,
.pagination li.next a {
  background-color: #5eddd8;
}

.pagination li.previous.disabled a,
.pagination li.next.disabled a {
  color: #ccc;
  cursor: not-allowed;
  background-color: #f7f7f7;
}
.pagination .active > .page-link {
  background: #3dc8af;
  border-color: #3dc8af !important;
}

.pagination li.break a {
  cursor: default;
}
.flat-table {
  height: 400px;
  overflow-y: auto;
}
.flat-table thead {
  position: sticky;
  top: 0;
}

.see-more {
  display: flex;
  justify-content: end;
  color: #3dc8af;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
}

.modal-btn {
  width: 72px;
  margin-right: 8px;
  margin-left: 8px;
  background-color: #3dc8af;
  color: #fff;
}

.modal-btn:hover {
  color: #fff;
  background-color: #38ad98;
}

.update-class{
  padding: 14px !important;
  background-color: #3dc8af;
  border-color:#3dc8af ;
}
.update-class:hover {
  color: #fff;
  border-color:#3dc8af ;
  background-color: #38ad98;
}
.LoadingActionWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(30, 46, 31, 0.6);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  z-index: 999;
}


.add-booking{
  background-color: #3dc8af;
  border-color:#3dc8af ;
  padding:6px 8px !important;
}
.add-booking:hover {
  color: #000;
  border-color:#3dc8af ;
  background-color: #fff;
}

.add-booking:focus{
  color: #000 !important;
  border-color:#3dc8af !important ;
  background-color: #fff !important;
}
.add-booking:focus-visible{
  color: #000 !important;
  border-color:#3dc8af !important;
  background-color: #fff !important;
}


.btn-books{
	background: #3DC8AF;
	font-weight: 700;
	color: #fff;
	font-size: 16px;
	padding: 6px 20px;
  border-color:#3dc8af ;
  border-radius: 20px;

}

.btn-books:hover{
	background: #fff !important;
	font-weight: 700;
	color: black;
	font-size: 16px;
	padding: 6px 20px;
  border-color:#3dc8af ;
}

.btn-books.show{
  background: hsl(169, 56%, 51%); 
  border-color:#3dc8af ;
}


.service-form label {
	color: #7C808E;
	font-weight: 600;
}

.service-form input.form-control {
	border: 0.5px solid #dbdbdb !important;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 10px;
}


input:focus{
  box-shadow: none !important;
  outline: none !important;
  border-color: #3dc8af !important ;
}



.btn:focus-visible{
  box-shadow: none !important;
  outline: none !important;
}

.btn:focus{
  box-shadow: none !important;
  outline: none !important;
}


.service-form select.form-select{
  border: 0.5px solid #dbdbdb !important;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 10px;
}

.service-form{
  color: #000 !important;
}


.grid_Services.new ul{
  margin-bottom: 0px;
}
.err-msg{
  color:red
}
.select-form .btn-secondary:hover {
  background: #3dc8af;
}


.btn-invite{
  background-color: #3dc8af ;
  color: white;
  padding:5px 15px !important;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: bold;
  border:1px solid #3dc8af;
}

.btn-invite:hover{
  background-color: #fff;
  color: #FF4D67;
  border:1px solid #FF4D67;
}

.btn-invite.next{
  padding:10px !important;
}
.vip-model{
  border:1px solid #008000;
  color:#008000
}


.btn-invite.new{
  padding: 10px  50px !important;
}

.btn-closed.new{
  padding: 10px  50px !important;
}
.search-user {
  display: flex;
  justify-content: flex-end;
}

.search-input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 10px;
  padding: .375rem .75rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-bottom: 10px;
}

.common-approv{
  padding: 7px 12px;
}
.password-hide-show{
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
}